import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import CustomButton, {
  CustomOutlineButton,
} from "../../components/custom-button";
import { useDeleteStaff } from "../../hooks/staff.hooks";

const AddBrokerDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddBrokerDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AddBrokerDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteBrokerModal = ({ userId, modalStatus, handleModal }) => {
  const deleteBroker = useDeleteStaff();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteBroker = () => {
    deleteBroker.mutate(userId, {
      onSuccess: () => {
        enqueueSnackbar("Delete broker successfully", { variant: "success" });
        handleModal(false);
      },
      onError: (errors) => {
        const {
          data: { message },
        } = errors;

        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };

  const handleCloseModal = () => {
    handleModal(false);
  };

  return (
    <div className="add-broker">
      <AddBrokerDialog
        className="add-broker__dialog"
        open={modalStatus}
        onClose={handleCloseModal}
      >
        <AddBrokerDialogTitle
          id="add-broker-dialog-title"
          onClose={handleCloseModal}
        >
          Delete Broker?
        </AddBrokerDialogTitle>
        <DialogActions>
          <CustomOutlineButton
            label="No, Keep Broker"
            onClick={handleCloseModal}
          />
          <CustomButton
            label="Yes, Delete Broker"
            type="submit"
            onClick={handleDeleteBroker}
            isLoading={deleteBroker.isLoading}
            disabled={deleteBroker.isLoading}
          />
        </DialogActions>
      </AddBrokerDialog>
    </div>
  );
};

DeleteBrokerModal.propTypes = {};

export default DeleteBrokerModal;
