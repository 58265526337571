import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { STAFF_ROLE, USER_ROLE } from "../../commons/enum";
import CustomButton, {
  CustomOutlineButton,
} from "../../components/custom-button";
import { useCompanyInfo, useCompanyList } from "../../hooks/company.hooks";
import { useCreateStaff, useUpdateStaffById } from "../../hooks/staff.hooks";
import { useMyInfo } from "../../hooks/user.hooks";
import { splitNameFromFullName } from "../../utils/user-data";

const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  brokerName: Yup.string("Enter broker name").required("Name is required"),
  phoneNumber: Yup.string("Enter broker Phone Number").required(
    "Phone Number is required"
  ),
  connectiveAccountId: Yup.string("Enter broker Connective Id"),
  introductionVideoUrl: Yup.string("Enter Introduction Video URL"),
  msTeamHook: Yup.string("Enter Microsoft Team Hook id"),
  slackHook: Yup.string("Enter Slack Hook id"),
  activeCampaignContactListId: Yup.string("Enter Active Campaign List id"),
  calendlyUrl: Yup.string("Enter broker Calendly URL"),
  companyId: Yup.string("Enter Company").required("Company is required"),
});

const CreateBrokerInputContainer = ({
  title,
  type,
  handleUpdateParams,
  fieldType = "text",
  params,
  errors,
  touched,
}) => {
  return (
    <div className="add-broker__field">
      <div className="add-broker__field-title">{title}</div>
      <TextField
        label=""
        id={type}
        name={type}
        value={params[type]}
        type={fieldType}
        size="small"
        error={touched[type] && Boolean(errors[type])}
        helperText={touched[type] && errors[type]}
        className={`add-broker__field-input ${type}`}
        onChange={handleUpdateParams}
      />
    </div>
  );
};

const AddBrokerDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddBrokerDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AddBrokerDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BrokerModal = ({ modalStatus, handleModal, brokerDetails = {} }) => {
  const createBroker = useCreateStaff();
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();
  const { data: companyList = [] } = useCompanyList();
  const { data: companyInfo } = useCompanyInfo();

  const { data: userInfo } = useMyInfo();

  const updateBrokerDetailById = useUpdateStaffById();

  const shouldUpdateBroker = useMemo(() => {
    return Boolean(Object.keys(brokerDetails).length > 0);
  }, [brokerDetails]);
  const {
    introductionVideoUrl = "",
    activeCampaignContactListId = "",
    brokerIntegrationSetting = {},
    calendlyUrl = "",
    id: staffId,
    contactChannels: { msTeamHook, slackHook } = {},
    companyId,
    user: { firstName, lastName, phoneNumber, email } = {},
    staffRoles,
  } = brokerDetails;

  const isCompanyAdmin = staffRoles?.some(
    (role) => role.role === STAFF_ROLE.COMPANY_ADMIN
  );

  const getStaffRole = staffRoles
    ?.filter((role) => role.role !== STAFF_ROLE.COMPANY_ADMIN)
    ?.map((role) => role.role);

  const fullName = useMemo(() => {
    if (firstName) {
      return `${firstName} ${lastName}`;
    }
    return null;
  }, [firstName, lastName]);

  const formik = useFormik({
    initialValues: {
      email: email || "",
      brokerName: fullName || "",
      phoneNumber: phoneNumber || "",
      connectiveAccountId: brokerIntegrationSetting?.connectiveAccountId || "",
      introductionVideoUrl: introductionVideoUrl || "",
      companyId: companyId || companyInfo?.id,
      msTeamHook: msTeamHook || "",
      slackHook: slackHook || "",
      staffRole: staffRoles ? getStaffRole : ["BROKER"],
      activeCampaignContactListId: activeCampaignContactListId || "",
      calendlyUrl: calendlyUrl || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const [brokerFirstName, brokerLastName] = splitNameFromFullName(
        values.brokerName
      );
      const brokerDetailParams = {
        userDetails: {
          email: values.email,
          firstName: brokerFirstName,
          lastName: brokerLastName,
          phoneNumber: values.phoneNumber,
        },
        staffDetails: {
          brokerDetails: {
            brokerId: brokerDetails.id,
            connectiveAccountId: values.connectiveAccountId,
            contactChannels: {
              msTeamHook: values.msTeamHook,
              slackHook: values.slackHook,
            },
          },
          companyId: values.companyId,
          staffRole: values.staffRole,
          introductionVideoUrl: values.introductionVideoUrl,
          activeCampaignContactListId: values.activeCampaignContactListId,
          calendlyUrl: values.calendlyUrl,
        },
      };
      if (shouldUpdateBroker) {
        // eslint-disable-next-line no-use-before-define
        await handleUpdateBroker(staffId, brokerDetailParams);
      } else {
        // eslint-disable-next-line no-use-before-define
        await handleCreateBroker(brokerDetailParams);
      }
    },
  });

  const handleUpdateBroker = async (brokerId, brokerDetailParams) => {
    updateBrokerDetailById.mutate(
      { id: brokerId, params: brokerDetailParams },
      {
        onSuccess: () => {
          enqueueSnackbar("Update Broker Details Successfully!", {
            variant: "success",
          });
          queryClient.invalidateQueries("staffList");
          handleModal(false);
          formik.resetForm();
        },
        onError: (error) => {
          enqueueSnackbar(error?.data.message || "Internal Server Error", {
            variant: "error",
          });
        },
      }
    );
  };

  const handleCreateBroker = async (brokerDetailParams) => {
    createBroker.mutate(brokerDetailParams, {
      onSuccess: () => {
        enqueueSnackbar("Create Broker successfully", { variant: "success" });
        handleModal(false);
        formik.resetForm();
      },
      onError: (errors) => {
        const {
          data: { message },
        } = errors;

        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };

  const handleCloseModal = () => {
    handleModal(false);
    formik.resetForm();
  };

  const handleSelectCompanyId = (e) => {
    formik.setFieldValue("companyId", e.target.value);
  };

  const generateFieldList = () => {
    const fieldList = [
      { title: "Email Address", fieldType: "email", type: "email" },
      { title: "Broker Name", type: "brokerName" },
      { title: "Phone Number", type: "phoneNumber" },
      { title: "Connective Account Id", type: "connectiveAccountId" },
      { title: "Calendly Url", type: "calendlyUrl" },
      { title: "Wistia Video URL", type: "introductionVideoUrl" },
      { title: "Microsoft Team Hook", type: "msTeamHook" },
      { title: "Slack Hook", type: "slackHook" },
    ];

    return fieldList.map((field) => (
      <CreateBrokerInputContainer
        key={field.title}
        handleUpdateParams={formik.handleChange}
        title={field.title}
        type={field.type}
        fieldType={field.fieldType}
        params={formik.values}
        errors={formik.errors}
        touched={formik.touched}
      />
    ));
  };

  return (
    <div className="add-broker">
      <AddBrokerDialog
        className="add-broker__dialog"
        open={modalStatus}
        onClose={handleCloseModal}
      >
        <AddBrokerDialogTitle
          id="broker-dialog-title"
          onClose={handleCloseModal}
        >
          {shouldUpdateBroker ? "Update Broker" : "Create new broker"}
        </AddBrokerDialogTitle>
        <DialogContent>
          <form className="add-broker__fields" onSubmit={formik.handleSubmit}>
            {generateFieldList()}
            {userInfo?.userRole === USER_ROLE.ADMIN && !isCompanyAdmin && (
              <div className="add-broker__field">
                <div className="add-broker__field-title">Company Id</div>
                <FormControl
                  sx={{ minWidth: 120, width: "100%" }}
                  className="add-broker__select-company"
                  error={formik.errors.companyId}
                >
                  <Select
                    value={formik.values.companyId}
                    onChange={handleSelectCompanyId}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {companyList?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors?.companyId && (
                    <FormHelperText>Company is required</FormHelperText>
                  )}
                </FormControl>
              </div>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <CustomOutlineButton label="Cancel" onClick={handleCloseModal} />
          <CustomButton
            label={shouldUpdateBroker ? "Update" : "Create"}
            type="submit"
            onClick={formik.handleSubmit}
            isLoading={createBroker.isLoading}
            disabled={createBroker.isLoading}
          />
        </DialogActions>
      </AddBrokerDialog>
    </div>
  );
};

BrokerModal.propTypes = {};

export default BrokerModal;
